<template>
    <styled-card id="dealer-overview">
        <template #heading>
            Account Overview
        </template>
        <div class="wrapper">
            <div class="dealer-info">
                <div class="property">
                    <p class="label">
                        Account ID
                    </p>
                    <p class="value">
                        <copy-text
                            :show-icon="true"
                            :value="dealer.id" />
                    </p>
                </div>
                <div
                    v-if="dealer.address"
                    class="property">
                    <p class="label">
                        Address
                    </p>
                    <p class="value">
                        <a
                            target="_blank"
                            @click="$emit('input', true)">
                            {{ dealer.address }} {{ dealer.city }} {{ dealer.state }} {{ dealer.zip }}
                        </a>
                        <action-button
                            icon="edit"
                            size="15"
                            @click="$emit('input', true)" />
                    </p>
                </div>
                <div
                    v-if="dealer.phone"
                    class="property">
                    <editable-data-field
                        label="Phone Number"
                        :value="dealer.phone"
                        :rules="phoneRules"
                        :handler="onPhoneNumberChanged" />
                </div>
                <div
                    v-if="dealer.site_base_url"
                    class="property">
                    <editable-data-field
                        label="Website"
                        :value="dealer.site_base_url"
                        :rules="urlRules"
                        :handler="onWebsiteChanged">
                        <template #actions>
                            <action-button
                                v-clipboard:copy="dealer.site_base_url"
                                v-clipboard:success="onCopy"
                                class="ml-2"
                                size="17"
                                icon="copy" />
                            <action-button
                                class="ml-2"
                                :href="dealer.site_base_url"
                                size="16"
                                target="_blank"
                                icon="outbound-link" />
                        </template>
                    </editable-data-field>
                </div>
                <div
                    v-if="dealer.timezone"
                    class="property">
                    <div
                        v-if="timezones == null"
                        class="value loading">
                        Loading...
                    </div>
                    <editable-data-field
                        v-if="timezones != null"
                        label="Timezone"
                        :value="dealer.timezone.display_name"
                        :handler="onTimezoneChanged">
                        <template #content>
                            <v-select
                                v-model="timezone"
                                class="styled-field"
                                label="Timezone"
                                return-object
                                item-text="display_name"
                                :items="timezones" />
                        </template>
                    </editable-data-field>
                </div>
                <div
                    v-if="dealer.vertical"
                    class="property">
                    <div
                        v-if="verticals == null"
                        class="value loading">
                        Loading...
                    </div>
                    <editable-data-field
                        v-if="verticals != null"
                        label="Industry"
                        :value="vertical.name == 'other' ? otherVertical : dealer.vertical.display_name"
                        :handler="onVerticalChanged">
                        <template #content>
                            <v-select
                                v-model="vertical"
                                class="styled-field mb-3"
                                label="Industry"
                                return-object
                                item-text="display_name"
                                :items="verticals" />
                            <v-text-field
                                v-if="vertical.name == 'other'"
                                v-model="otherVertical"
                                class="styled-field mb-3"
                                label="Industry" />
                        </template>
                    </editable-data-field>
                </div>
                <div class="property">
                    <p class="label">
                        Ad Preview
                    </p>
                    <p class="value">
                        Ad Preview Generator Link
                        <action-button
                            v-clipboard:success="() => $flash('Ad Preview Generator link copied to your clipboard!', 'green')"
                            v-clipboard:copy="handleAdPreviewGeneratorLink()"
                            icon="copy"
                            position="left"
                            size="17"
                            class="pa-2" />
                        <action-button
                            :href="handleAdPreviewGeneratorLink()"
                            size="16"
                            target="_blank"
                            icon="outbound-link" />
                    </p>
                </div>
            </div>
            <div
                v-if="coordinates">
                <div class="dealer-map">
                    <gmap-map
                        :center="coordinates"
                        :zoom="13"
                        :options="{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            disableDefaultUi: true
                        }"
                        class="google-map">
                        <gmap-marker :position="coordinates" />
                    </gmap-map>
                    <div class="map-desc">
                        <a
                            target="_blank"
                            @click="$emit('input', true)">
                            Edit primary location
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </styled-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';
import GmapMap from 'vue2-google-maps/src/components/map';
import GmapMarker from 'vue2-google-maps/src/components/marker';
import CopyText from '@/components/globals/CopyText';
import EditableDataField from '@/components/globals/fields/EditableDataField';
import validationRules from '../../../helpers/validationRules';

export default {
    name: 'DealerOverview',
    components: {
        StyledCard,
        ActionButton,
        CopyText,
        GmapMap,
        GmapMarker,
        EditableDataField
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            yesterday: new Date(Date.now()-1*24*60*60*1000),
            address: null,
            city: null,
            state: null,
            zip: null,
            timezone: null,
            timezones: null,
            vertical: null,
            verticals: null,
            otherVertical: null,
            phoneRules: [],
            urlRules: [],
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer,
        }),
        ...mapGetters([
            'dealerPlatforms'
        ]),
        coordinates() {
            if (!this.dealer.latitude || !this.dealer.longitude) {
                return false;
            }

            return {
                lat: parseFloat(this.dealer.latitude),
                lng: parseFloat(this.dealer.longitude)
            };
        },
        website() {
            if (!this.dealer.site_base_url) {
                return null;
            }
            const url = new URL(this.dealer.site_base_url);
            return url.hostname;
        },
        platforms() {
            return this.dealerPlatforms.map(platform => platform.name);
        }
    },
    async created() {
        await this.$store.dispatch('waitForDealerLoaded');
        this.address = this.dealer.address;
        this.city = this.dealer.city;
        this.state = this.dealer.state;
        this.zip = this.dealer.zip;
        this.timezone = this.dealer.timezone;
        this.vertical = this.dealer.vertical;
        if (this.dealer.vertical && this.dealer.vertical.name == 'other') {
            this.otherVertical = this.dealer.configuration.vertical_other;
        }
        this.phoneRules = validationRules.phoneRules(this.dealer.country_code);
        this.urlRules = validationRules.urlRules;
        this.getTimezones();
        this.getVerticals();
    },
    methods: {
        async onPhoneNumberChanged(data) {
            await this.$http.put('/dealers/' + this.dealer.id, {
                phone: data?.input ?? null
            });
            this.$store.dispatch('updateCurrentDealer');
        },
        async onWebsiteChanged(data) {
            await this.$http.put('/dealers/' + this.dealer.id, {
                site_base_url: data?.input ?? null
            });
            this.$store.dispatch('updateCurrentDealer');
        },
        async onAddressChanged() {
            await this.$http.put('/dealers/' + this.dealer.id, {
                address: this.address,
                city: this.city,
                state: this.state,
                zip: this.zip
            });
            this.$store.dispatch('updateCurrentDealer');
        },
        async onTimezoneChanged() {
            await this.$http.put('/dealers/' + this.dealer.id, {
                timezone_id: this.timezone.id
            });
            this.$store.dispatch('updateCurrentDealer');
        },
        async onVerticalChanged() {
            if (this.vertical.name == 'other') {
                const configuration = this.dealer.configuration ? this.dealer.configuration : {};
                configuration.vertical_other = this.otherVertical;

                await this.$http.put('/dealers/' + this.dealer.id, {
                    vertical_id: this.vertical.id,
                    configuration
                });
            } else {
                await this.$http.put('/dealers/' + this.dealer.id, {
                    vertical_id: this.vertical.id
                });
            }

            this.$store.dispatch('updateCurrentDealer');
        },
        async getTimezones() {
            const response = await this.$apiRepository.getTimezones();

            this.timezones = response.data.data;
        },
        async getVerticals() {
            const response = await this.$apiRepository.getVerticals();

            this.verticals = response.data.data;
        },
        onCopy(e) {
            this.$flash('\'' + e.text + '\' copied to clipboard!', 'green');
        },
        handleAdPreviewGeneratorLink() {
            let baseUrl = document.location.origin;

            if (
                process.env.VUE_APP_APP_ENV === 'production'
                && this.dealer.agency.primary_domain
            ) {
                baseUrl = this.dealer.agency.primary_domain;
            }

            return `${baseUrl}/ad-preview-generator?status=active&dealerUuid=${this.dealer.uuid}&platforms=${this.platforms}`;
        }
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    padding: 30px;
}
.dealer-info,
.dealer-map {
    flex: 1;
    padding: 30px;
}
.dealer-info {
    display: flex;
    flex-wrap: wrap;
}
.property {
    width: 50%;
    .label {
        font-size: 12px;
        color: $gray-text;
        margin: 0;
    }
    .value {
        margin-left: 10px;
    }
}
.dealer-map {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.map-desc{
    background: #F9FAFA;
    text-align: center;
    padding: 5px;
    width: 100%;
}
.google-map {
    border: 1px solid #979797;
    width: 450px;
    height: 250px;
}
@keyframes fading {
    0% {
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.loading{
    animation: fading 2s infinite linear;
}
</style>
